import api from "app/api"
import { lookLoader } from "common/src/actions/looks";
import downloadUrl from "common/src/lib/downloadUrl"
import { getPseCommission, defaultRates } from "common/src/lambdalib/userPaymentSettings"
import { getProductRows as partnerizeProductRows } from "app/components/pages/reports/network/partnerize"
import { getProductRows as rakutenProductRows } from "app/components/pages/reports/network/rakuten"
import { getProductRows as cjProductRows } from "app/components/pages/reports/network/cj"

import hub from "common/src/hub";

const productRows = {
    partnerize: partnerizeProductRows,
    rakuten: rakutenProductRows,
    cj: cjProductRows
}

export const PER_PAGE = 50;
const usrGraph = "id givenName familyName avatar handle paymentSettings { pseCommission }"


function dateToTimestamp(d, time) {
    if (d.indexOf("T") === -1) {
        d += ' ' + time;
    }
    return d;
}


function setBodyDates(body, dateRange) {
    if (dateRange.startDate && dateRange.endDate) {
        body.start = dateToTimestamp(dateRange.startDate, '00:00:00');
        body.end = dateToTimestamp(dateRange.endDate, '23:59:59');
    }
}

function setBodyIds(body, key, list) {
    if (list && list.length > 0) {
        body[key] = idmap(list);
    }
}

function setBodyFlag(body, key, value) {
    if (value === true || value === false) {
        body[key] = value;
    }
}

function getRequestBody(currency, filters) {
    const body = {};

    if (currency) {
        body.currency = currency;
    }

    setBodyDates(body, filters);
    setBodyIds(body, "networks", filters.networks);
    setBodyIds(body, "advertisers", filters.advertisers);
    setBodyIds(body, "campaigns", filters.campaigns);
    setBodyIds(body, "fris", filters.fris);
    setBodyIds(body, "users", filters.users);
    setBodyIds(body, "contributors", filters.contributors);
    setBodyIds(body, "friOrContributors", filters.pses);
    setBodyIds(body, "statuses", filters.statuses);
    setBodyIds(body, "userGroups", filters.userGroups);

    if (filters.query) {
        body.query = filters.query;
    }
    if (filters.withFri !== undefined && filters.withFri !== null) {
        body.withFri = filters.withFri;
    }
    if (filters.withContributor !== undefined && filters.withContributor !== null) {
        body.withContributor = filters.withContributor;
    }
    if (filters.withUser !== undefined && filters.withUser !== null) {
        body.withUser = filters.withUser;
    }
    if (filters.group && filters.group.length > 0) {
        body.group = filters.group[0].id;
    }
    if (filters.pses && filters.pses.length > 0) {
        body.withFriOrContributor = true;
    }

    setBodyFlag(body, "approved", filters.approved);
    setBodyFlag(body, "noSum", filters.noSum);
    setBodyFlag(body, "nonZero", filters.nonZero);

    return body;
}

function idmap(list) {
    return list.map(item => {
        return typeof item === "string" ? item : item.id;
    });
}

export async function loadCommissions(filters, page, csv = false, byProduct = false) {

    const body = getRequestBody(null, filters);
    body.csv = csv;
    body.byProduct = byProduct;
    const limit = PER_PAGE;
    const offset = page * PER_PAGE;

    if (!csv) {
        body.limit = limit;
        body.offset = offset;
    }

    const response = await api.reporting.post("/report/orders/list", { body });

    if (csv) {
        downloadUrl(response.url, "sales.csv");
        return;
    }

    const { rows, data } = response;
    const count = data.count;
    const { commissionAmount, saleAmount, grossCommissionAmount, grossSaleAmount } = data.sum;


    if (rows && rows.length > 0) {
        const where = { id: { _in: rows } };
        const order = { orderDate: "desc" };
        let commissions = await api.networkOrder.list({ where, order })
            .then(list => list.map(row => {
                row.pseGrossCommission = 0;
                row.pseCommission = 0;
                const user = row.click?.product?.look?.fri ||
                    row.click?.referenceUser;
                if (user) {
                    row.pseGrossCommission = getPseCommission(
                        row.grossCommissionAmount,
                        user.paymentSettings?.pseCommission,
                        row.pseCommissionRate
                    );
                    row.pseCommission = getPseCommission(
                        row.commissionAmount,
                        user.paymentSettings?.pseCommission,
                        row.pseCommissionRate
                    );
                }
                return row;
            }));

        if (commissions && commissions.length > 0) {
            const cpids = commissions.map(row => row.click?.catalogueProductId)
                .filter(cpid => !!cpid)
                .filter((id, inx, self) => self.indexOf(id) === inx);
            //console.log(cpids)
            if (cpids.length > 0) {
                const body = {
                    stage: "live",
                    product_web_id: cpids,
                    limit: 1000
                }
                const { products } = await api.unauth.post("/catalogue/search", { body });
                //console.log(products)
                commissions.forEach(c => {
                    if (c.click?.catalogueProductId) {
                        const cp = products?.find(p => p.product_web_id === c.click?.catalogueProductId);
                        if (cp) {
                            c.click.catalogueProduct = cp;
                        }
                    }
                })

                const body2 = {
                    stage: "live",
                    product_catalogue_id: cpids,
                    limit: 1000
                }
                const { products: products2 } = await api.unauth.post("/catalogue/search", { body: body2 });
                //console.log(products)
                commissions.forEach(c => {
                    if (c.click?.catalogueProductId) {
                        const cp = products2?.find(p => p.product_catalogue_id === c.click?.catalogueProductId);
                        if (cp) {
                            c.click.catalogueProduct = cp;
                        }
                    }
                })
            }
        }

        if (byProduct) {
            const cmsByProduct = [];

            commissions.forEach(order => {
                const prows = productRows[order.networkId]?.(order) || [];
                //console.log(prows)
                prows.forEach(row => {
                    const user = order.click?.product?.look?.fri ||
                        order.click?.referenceUser;
                    row.pseCommission = 0;
                    if (user) {
                        row.pseCommission = getPseCommission(
                            row.commissionAmount,
                            user.paymentSettings?.pseCommission,
                            order.pseCommissionRate
                        );
                    }
                    cmsByProduct.push(row);
                })
            });

            commissions = cmsByProduct;
        }

        //console.log(commissions)
        return {
            commissions, count, commissionAmount, saleAmount,
            grossCommissionAmount, grossSaleAmount,
            revenue: commissionAmount * (1 - defaultRates.pse)
        };
    }
    else {
        return {
            commissions: [], count: 0,
            commissionAmount: 0, saleAmount: 0,
            grossCommissionAmount: 0, grossSaleAmount: 0,
            revenue: 0
        };
    }
}

export async function loadCommissionsByPse(filters, csv = false) {

    const body = getRequestBody(null, filters);
    body.csv = csv;

    const response = await api.reporting.post("/report/orders/byreceiver", { body });

    if (csv) {
        downloadUrl(response.url, "byPSE.csv");
        return;
    }

    const { rows } = response;
    const userIds = rows.map(c => c.userId).filter((id, inx, self) => self.indexOf(id) === inx);
    const users = await api.user.list({ where: { id: { _in: userIds } } }, usrGraph);

    return rows.map(row => {
        row.user = users.find(f => f.id === row.userId);
        if ("pseGrossCommissionAmount" in row) {
            row.pseGrossCommission = row.pseGrossCommissionAmount;
            row.pseCommission = row.pseCommissionAmount;
        }
        else {
            row.pseGrossCommission = getPseCommission(
                row.grossCommissionAmount,
                row.user?.paymentSettings?.pseCommission,
                row.pseCommissionRate
            );
            row.pseCommission = getPseCommission(
                row.commissionAmount,
                row.user?.paymentSettings?.pseCommission,
                row.pseCommissionRate
            );
        }
        return row;
    });
}



export async function loadCommissionsByFri(filters, csv = false) {

    const body = getRequestBody(null, filters);
    body.csv = csv;

    const response = await api.reporting.post("/report/orders/byfri", { body });

    if (csv) {
        downloadUrl(response.url, "byfri.csv");
        return;
    }

    const { rows } = response;
    const friIds = rows.map(c => c.friId).filter((id, inx, self) => self.indexOf(id) === inx);
    const fris = await api.user.list({ where: { id: { _in: friIds } } }, usrGraph);

    return rows.map(row => {
        row.fri = fris.find(f => f.id === row.friId);
        return row;
    });
}


export async function loadCommissionsByContributor(filters, csv = false) {

    const body = getRequestBody(null, filters);
    body.csv = csv;

    const response = await api.reporting.post("/report/orders/bycontributor", { body });

    if (csv) {
        downloadUrl(response.url, "bycontributor.csv");
        return;
    }

    const { rows } = response;
    const refIds = rows.map(c => c.referenceUserId).filter((id, inx, self) => self.indexOf(id) === inx);
    const infs = await api.user.list({ where: { id: { _in: refIds } } }, usrGraph);

    return rows.map(row => {
        row.contributor = infs.find(i => i.id === row.referenceUserId);
        return row;
    });
}


export async function loadCommissionsByDate(filters, csv = false) {

    const body = getRequestBody(null, filters);
    body.csv = csv;

    const response = await api.reporting.post("/report/orders/bydate", { body });

    if (csv) {
        downloadUrl(response.url, "bydate.csv");
        return;
    }

    const { rows } = response;
    return rows;
}


export async function loadCommissionsByLook(filters) {

    const body = getRequestBody(null, filters);
    const response = await api.reporting.post("/report/orders/bylook", { body });
    const { rows } = response;
    const lookIds = rows.map(c => c.lookId)
        .filter((id, inx, self) => self.indexOf(id) === inx);
    const looks = await lookLoader({ where: { id: { _in: lookIds } } });

    return rows.map(row => {
        const look = looks.find(l => l.id === row.lookId);
        if (look) {
            look.commissions = row;
            return look;
        }
        else {
            return null;
        }
    })
        .filter(look => !!look);
}





export async function loadCommissionsByCampaign(filters, csv = false) {

    const campaigns = {
        "test": "Test"
    }

    const body = getRequestBody(null, filters);
    body.csv = csv;

    const response = await api.reporting.post("/report/orders/bycampaign", { body });

    if (csv) {
        downloadUrl(response.url, "bycampaign.csv");
        return;
    }

    const { rows } = response;
    const commissions = rows.map(row => {
        row.name = campaigns[row.campaignId] || row.campaignId;
        return row;
    });

    return commissions;
}

export async function loadCommissionsByNetwork(filters, csv = false) {

    const body = getRequestBody(null, filters);
    body.csv = csv;
    const networks = await api.network.list();
    const response = await api.reporting.post("/report/orders/bynetwork", { body });

    if (csv) {
        downloadUrl(response.url, "bynetwork.csv");
        return;
    }

    const { rows } = response;
    const commissions = rows.map(row => {
        row.name = networks.find(n => n.id === row.networkId).name;
        return row;
    });

    return commissions;
}


export async function loadCommissionsByAdvertiser(filters, csv = false) {
    const body = getRequestBody(null, filters);
    body.csv = csv;
    const response = await api.reporting.post("/report/orders/byadvertiser", { body });

    if (csv) {
        downloadUrl(response.url, "byadvertiser.csv");
        return;
    }

    const { rows } = response;
    return rows;
}

export async function setRakutenItemOrderStatus(itemId, manualStatus) {
    if (!itemId) {
        return;
    }

    await api.networkOrderRakutenItem.update(
        { id: { _eq: itemId } },
        {
            manualStatus
        },
        "affected_rows"
    );

    hub.dispatch("app", "order-item-status-update");
}